import React from 'react'
import cx from 'classnames'
import { Message } from 'intl'

import { Href, type HrefProps } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'
import type { TextStyle, TextColor } from 'components/dataDisplay'


type NavItemProps = Pick<Navigation.Item, 'to' | 'href' | 'toTab' | 'title' | 'label' | 'exclusiveLabel' | 'content' | 'ref'> & {
  className?: string
  style?: TextStyle
  color?: TextColor
  hoverColor?: 'gold-30'
  labelColor?: TextColor
  onClick?: React.MouseEventHandler
  closeNav?: () => void
  withArrow?: boolean
  'data-testid'?: string
} & AllOrNothing<{
  activeColor?: TextColor
  isActive?: boolean
}>

const NavItem = React.forwardRef<HTMLAnchorElement, NavItemProps>((props, ref) => {
  const {
    className, to, href, toTab, title, label, exclusiveLabel, withArrow, content, style,
    color, hoverColor, activeColor, labelColor = 'gold-50', isActive,
    onClick, closeNav, 'data-testid': dataTestId,
  } = props

  const isLabelVisible = Boolean(label)
  const isExclusiveLabelVisible = Boolean(exclusiveLabel)

  const handleClick: HrefProps['onClick'] = (event) => {
    if (typeof closeNav === 'function') {
      closeNav()
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }
  }

  const itemClassNames = cx(className, {
    [`text-${color}`]: color && !isActive,
    [`hover:text-${hoverColor}`]: hoverColor,
    [`text-${activeColor}`]: activeColor && isActive,
    [`text-${style}`]: style,
    'cursor-pointer': !isActive,
  })

  const renderContent = () => {
    if (content) {
      return (
        <>{content}</>
      )
    }

    const formattedTitle = (
      <Message value={title} html />
    )

    if (isExclusiveLabelVisible) {
      return (
        <span className="flex items-center">
          {formattedTitle}
          {exclusiveLabel}
        </span>
      )
    }
    if (isLabelVisible) {
      return (
        <>
          {formattedTitle}
          <Text className="ml-4 inline-block w-0" message={label} style="eye3" color={labelColor} tag="sup" />
        </>
      )
    }

    if (withArrow) {
      return (
        <>
          {formattedTitle}
          <Icon name="other/long-arrow-right" />
        </>
      )
    }

    return formattedTitle
  }

  return (
    <Href
      ref={ref}
      className={itemClassNames}
      to={to}
      href={href}
      toTab={toTab}
      data-testid={dataTestId}
      aria-current={isActive ? 'page' : undefined}
      onClick={handleClick}
    >
      { renderContent() }
    </Href>
  )
})


export default NavItem
